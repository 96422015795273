<template>
  <fr-button
    :icon="theme.icon"
    size="xs"
    class="border border-fr-gray-250 !bg-fr-gray-200 !text-fr-gray-900 !shadow-none dark:border-none dark:!bg-fr-gray-850 dark:!text-fr-gray-250"
  >
    <q-tooltip>
      Theme picker
      <p>Current: {{ theme?.label }} {{ themeStyle?.label }}</p>
    </q-tooltip>

    <!-- <span class="hidden md:block">
      {{ theme?.label }} {{ themeStyle?.label }}
    </span> -->

    <q-menu
      class="m-0 min-w-[140px] !bg-gray-800/10 p-0 shadow-none backdrop-blur-[20px]"
      transition-show="jump-down"
      transition-duration="400"
      :offset="[0, 5]"
    >
      <fr-card class="bg-transparent">
        <q-list separator :dark="$q.dark.isActive">
          <div class="fr-card-header font-bold tracking-wider">
            <span> Dark Mode </span>
          </div>
          <template v-for="mode in modes" :key="mode.type">
            <q-item clickable @click="setTheme(mode)">
              <q-checkbox
                :model-value="theme.type === mode.type"
                :label="mode.label"
                size="sm"
                @click.stop="setTheme(mode)"
              />
            </q-item>
          </template>
        </q-list>

        <q-list separator :dark="$q.dark.isActive">
          <div class="fr-card-header !rounded-none font-bold tracking-wider">
            <span>Theme Style</span>
          </div>

          <template v-for="style in styles" :key="style.type">
            <q-item clickable @click="setThemeStyle(style)">
              <q-checkbox
                :model-value="themeStyle.type === style.type"
                :label="style.label"
                size="sm"
                @click.stop="setThemeStyle(style)"
              />
            </q-item>
          </template>
        </q-list>
      </fr-card>
    </q-menu>
  </fr-button>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import { Dark } from 'quasar'
import factories from '~/factories'
import { modes, styles } from './theme-switch'

const authStore = useAuthStore()

const user = useUser()

const theme = computed({
  get: () => authStore.theme,
  set: (value) => (authStore.theme = value)
})

const themeStyle = computed({
  get: () => authStore.themeStyle,
  set: (value) => (authStore.themeStyle = value)
})

const setTheme = (payload: (typeof modes)[0]) => {
  theme.value = payload

  if (user.value?.id) {
    updateUserTheme({
      id: user.value?.id as number,
      themeColor: theme.value.type
    })
  }
}

const setThemeStyle = (payload: (typeof styles)[0]) => {
  themeStyle.value = payload

  if (user.value?.id) {
    updateUserTheme({
      id: user.value?.id as number,
      themeStyle: themeStyle.value.type
    })
  }
}

/**
 * Hooks
 */
const { mutateAsync: updateUserTheme } =
  factories.admin.mutations.useUpdateUserTheme()

/**
 * Watchers
 */
watch(
  () => theme.value,
  (val) => {
    if (val) {
      const isDark = val.type === 'dark'

      Dark.set(isDark)
      if (isDark) {
        document.querySelector('html')?.classList.add('dark')
      } else {
        document.querySelector('html')?.classList.remove('dark')
      }
    }
  },
  { immediate: true }
)

watch(
  () => themeStyle.value,
  (val) => {
    if (val) {
      if (val.type === 'neo') {
        document.querySelector('html')?.classList.add('neo')
        document.querySelector('html')?.classList.remove('chowey')
      } else {
        document.querySelector('html')?.classList.add('chowey')
        document.querySelector('html')?.classList.remove('neo')
      }
    }
  },
  { immediate: true }
)
</script>
